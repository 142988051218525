import React, { Component } from 'react';
import API from './../../../api/api';
import {Paper, Typography, TextField, Button, Checkbox} from '@material-ui/core';
import { manager } from './../../../App';
import { Link } from 'react-router-dom';
import logo from './../../styles/title.png';
import LoginButton from './../LoginButton';
import validateEmail from './emailValidator';

declare let window: any;

const styles = {
    root: {
        width: '90%',
        maxWidth: '440px',
        alignSelf: 'center',
        paddingTop: '15pt',
        paddingBottom: '15pt',
        boxShadow:'none',
        border: 'none',
        background: 'none',
    },
    button: {
        border: 'none',
        background: 'var(--active-color)',
        minHeight: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        color: 'var(--active-text-color)',
        marginTop:'35px',
        fontFamily: 'Poppins',
        fontWeight:500
    },
    alreadyHave:{
        fontSize:'14px',
        fontFamily:'Lato',
        color: 'white',
        fontWeight:300,
        textAlign: "center" as "center",
        marginTop: '50px'
    },
    input: {
        borderRadius: '14px'
    },
    orMainContainer: {
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        marginTop:'40px',
        marginBottom:'20px',
    },
    bar: {
        height: '1px',
        flex:1,
        backgroundColor:'rgba(255,255,255,0.5)'
    },
    or:{
        marginLeft:'47px',
        color:'white',
        marginRight:'47px',
        fontSize:'18px',
        fontFamily:'Lato'
    }
}
export default class RegisterPage extends Component<any, {form:any, errors:string[], done:boolean, confirmed: boolean}> {
    constructor(props:any){
        super(props);
        this.state = {
            form:{
                username:'',
                password:'',
                email:''
            },
            confirmed: false,
            errors:[],
            done:false
        }
    }

	
    componentDidMount(){
        manager.toggle(false);
    }

    handleChange = (e: any) => {
        const {form} = this.state;
        form[e.target.name] = e.target.value;
        this.setState({form});
    }
    handleChangeC = (e: any) => {
        this.setState({confirmed: e.target.checked});
    }

    register = () => {
        let errors = [];
        if(!this.state.form.username.length) errors.push("Username is required");
        if(!this.state.form.password.length) errors.push("Password is required");
        if(!this.state.form.email.length || !validateEmail(this.state.form.email)) errors.push("Valid email is required");
        if(!errors.length) return API.user.register(this.state.form, () => {
			this.props.cxt.login(this.state.form.username, this.state.form.password, () => {
				this.props.history.push('/');
			});
        }, (res: any) => {
            if(Array.isArray(res.errors)) this.setState({errors:res.errors});
        });
        return this.setState({errors});
    }

    render(){
        return (
            <div className="login-container">
                <div className="login-form-container">
            <Paper style={styles.root}>
            
            <Link to='/' className="logo-container"><img src={logo} className='logo-login'/></Link>
            <Typography variant="h5" component="h1" className="interactive-map-title">
                Assassin’s Creed Valhalla Interactive Map 
                <div className="beta-container">Beta</div>
                <div className="england-coming-soon">england coming soon</div>
            </Typography>
            {this.state.errors.length ? this.state.errors.map(error => <p key={error} style={{color:'white'}}>{error}</p>) : ""}
            <div className="form-container">
                <TextField
                    label="Username"
                    name='username'
                    value={this.state.form.username}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                        style: {
                            color: 'white'
                        }
                    }}
                    InputProps={{
                        classes: {
                            notchedOutline: 'input-login'
                        }
                    }}
                    InputLabelProps={{
                        classes: {
                            root: 'input-login-label'
                        }
                    }}
                />
                <TextField
                    label="Email"
                    name='email'
                    value={this.state.form.email}
                    onChange={this.handleChange}
                    margin="normal"
                    type='email'
                    variant="outlined"
                    inputProps={{
                        style: {
                            color: 'white'
                        }
                    }}
                    InputProps={{
                        classes: {
                            notchedOutline: 'input-login'
                        }
                    }}
                    InputLabelProps={{
                        classes: {
                            root: 'input-login-label'
                        }
                    }}
                />
                <TextField
                    label="Password"
                    name='password'
                    value={this.state.form.password}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    type='password'
                    inputProps={{
                        style: {
                            color: 'white'
                        }
                    }}
                    InputProps={{
                        classes: {
                            notchedOutline: 'input-login'
                        }
                    }}

                    InputLabelProps={{
                        classes: {
                            root: 'input-login-label'
                        }
                    }}
                />
                <div className="privacypolicy">
                    <Checkbox value={this.state.confirmed} onChange={this.handleChangeC} /> I understand and agree to <a href="/PrivacyPolicy.pdf" target="_blank">Privacy Policy</a> and <a href="/TermsandCondition.pdf" target="_blank">Terms of Service</a>
                </div>
                
                <Button variant="outlined" disabled={!this.state.confirmed} onClick={this.register} style={styles.button} >
                    Create my account
                </Button>
                <div style={styles.orMainContainer}>
                    <div style={styles.bar}></div>
                    <div style={styles.or}>or</div>
                    <div style={styles.bar}></div>
                </div>
                <LoginButton type="steam" register/>
                <LoginButton type="google" register/>
                <LoginButton type="battlenet" register/>
                <div className="privacypolicy">
                By signing up using Steam account or Google account or Battlenet account I understand and agree to <a href="/PrivacyPolicy.pdf" target="_blank">Privacy Policy</a> and <a href="/TermsandCondition.pdf" target="_blank">Terms of Service</a>
                </div>
                <div style={styles.alreadyHave}>
                    Already have an account? <Link to='/login' className="login-link">Log in</Link>
                </div>
            </div>
        </Paper>
                </div>
                <div className="register-side-background">

                </div>
            </div>
        )
    }
}
