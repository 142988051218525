import { stringify } from 'query-string';
import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';

import { apiCall, RequestMethod } from '../../../api/api';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl: string, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type: any, resource: string, params: any) => {
        let url = '';
        const options: any = {};
        console.log(type)
        switch (type) {
			case GET_MANY:
				// This implementation isn't what it should be, but it's enough for our situation
                url = `/${resource}`;
				break;
            case GET_LIST: {
				const { page } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
					page: page,
                };
                url = `/${resource}?${stringify(query)}`;
                break;
            }
            case GET_ONE:
                url = `/${resource}/${params.id}`;
                break;
            case GET_MANY_REFERENCE: {
                const { page } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
					page: page,
                };
                url = `/${resource}?${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `/${resource}/${params.id}`;
                options.method = 'PATCH';
                options.body = params.data;
                break;
            case CREATE:
                url = `/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response: any, type: any, resource: any, params: any) => {
        const json = response.data;
		const headers = response.headers || [];
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!headers.has('content-range')) {
                    throw new Error(
                        'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
                    );
                }
                return {
                    data: json,
                    total: parseInt(
                        headers
                            .get('content-range')
                            .split('/')
                            .pop(),
                        10
                    ),
                };
            case CREATE:
                return { data: { ...params.data, id: json.id } };
			case UPDATE:
				return { data: { ...params.data } };
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type: any, resource: any, params: any) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
				params.ids.map((id: any) => {
						return new Promise((resolve, reject) => apiCall({
							endPoint: `/${resource}/${id}`,
							method: RequestMethod.PATCH,
							body: JSON.stringify(params.data),
							cb: resolve,
							errCb: reject
						}, true))
					}
				)
            ).then((responses: any) => ({
                data: responses.map((response: any) => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
				params.ids.map((id: any) => {
						return new Promise((resolve, reject) => apiCall({
							endPoint: `/${resource}/${id}`,
							method: RequestMethod.DELETE,
							body: JSON.stringify(params.data),
							cb: resolve,
							errCb: reject
						}, true))
					}
				)
            ).then((responses: any) => ({
                data: responses.map((response: any) => response.json),
            }));
        }

        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );

		return new Promise((resolve, reject) => apiCall({
			endPoint: url,
			method: options.method,
			body: options.method === 'GET' ? JSON.stringify(params.data) : params.data,
			cb: resolve,
			errCb: reject
		}, true)).then((response: any) => convertHTTPResponse(response, type, resource, params));
    };
};
