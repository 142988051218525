import { Modal, Typography, Paper, TextField, Button, MenuItem, FormControlLabel, Checkbox, Switch, Snackbar } from "@material-ui/core";
import api from "../../api/api";
import React from "react";
import * as I from './../../api/interfaces';
import { EditingType } from './../modules/Maps/Marker';
import Alert from './Alert';
const styless = {
    root: {
        width: '95%',
        maxWidth: '625px',
        alignSelf: 'center',
        paddingTop: '65px',
        paddingBottom: '71px',
        outline: "none",
        borderRadius: '30px',
        margin: 'auto',
        marginBottom: '100px',
    },
    button: {
        border: 'none',
        background: 'var(--btn-special-background)',
        minHeight: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        color: 'white',
        fontFamily: 'Poppins',
        fontWeight: 500,
        marginTop: '10px'
    },
    buttonDisabled: {
        border: 'none',
        background: 'var(--btn-special-background)',
        minHeight: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        opacity: 0.5,
        color: 'white',
        fontFamily: 'Poppins',
        fontWeight: 500,
        marginTop: '10px'
    },
}

interface IProps {
    marker: I.ExtendedMarker,
    submit: any,
    toggleDrag: any,
    onOpen: () => void;
    onClose: () => void;
    categories: I.Category[],
    open: boolean;
    suggestion?: boolean
    toggleEditing: (type: EditingType) => () => void;
}

interface IState {
    form: any,
    done: boolean,
    suggestionSnackbar: boolean,
    editSnackbar: boolean,
    editSnackbarAdmin: boolean
}
export default class EditMarkerModal extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            form: {
                name: this.props.marker.name,
                description: this.props.marker.description,
                category: this.props.marker.category,
                isDLC: this.props.marker.isDLC,
                private: this.props.marker.private
            },
            done: false,
            suggestionSnackbar: false,
            editSnackbar: false,
            editSnackbarAdmin: false,
        }
    }

    submit = () => {

        if (this.props.marker.id === 0 && this.props.suggestion) {
            return;
        }
        if (this.props.marker.id === 0) {
            return;
        }
        if (this.props.suggestion) {
            const { form } = this.state;
            form.marker = this.props.marker.id;
            form.position = this.props.marker.position;
            return api.suggestions.add(this.state.form, () => {
                this.props.toggleEditing(null)();
                this.setState({ done: true, suggestionSnackbar: true }, this.props.onClose);
            })
        }
        const form = { ...this.state.form, position: this.props.marker.position };
        return api.markers.update(this.props.marker.id, form, () => {
            this.props.submit();
            this.props.toggleEditing(null)();
            this.setState({ done: true, editSnackbar: this.state.form.private, editSnackbarAdmin: !this.state.form.private }, this.props.onClose);
        });
    }

    /*componentDidMount() {
        api.categories.get((categories: any) => {
            this.setState({ categories });
        })
    }*/
    handleDLC = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { form } = this.state;
        form.isDLC = event.target.checked;
        this.setState({ form });
    };

    handlePrivate = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { form } = this.state;
        form.private = event.target.checked;
        this.setState({ form });
    };
    handleChange = (e: any) => {
        const { form } = this.state;
        form[e.target.name] = e.target.value;
        this.setState({ form });
    }
    isValid = () => {
        const { form } = this.state;
        return form.name && form.category > 1;
    }
    closeSnackbarSuggestion = () => {
        this.setState({ suggestionSnackbar: false })
    }
    closeSnackbarEditAdmin = () => {
        this.setState({ editSnackbarAdmin: false })
    }
    closeSnackbarEdit = () => {
        this.setState({ editSnackbar: false })
    }
    render() {
        const pos = this.props.marker.position;
        return <>

            <Snackbar
                open={this.state.editSnackbar}
                onClose={this.closeSnackbarEdit}
                autoHideDuration={4000}
            >
                <Alert type="success">
                    Your private place has been edited. You will see your changes soon.
		        </Alert>
            </Snackbar>
            <Snackbar
                autoHideDuration={4000}
                open={this.state.suggestionSnackbar}
                onClose={this.closeSnackbarSuggestion}
            >
                <Alert type="success">
                    Your suggestion has been added to review
		        </Alert>
            </Snackbar>
            <Snackbar
                autoHideDuration={4000}
                open={this.state.editSnackbarAdmin}
                onClose={this.closeSnackbarEditAdmin}
            >
                <Alert type="success">
                    Marker has been edited
		        </Alert>
            </Snackbar>
            <Modal
                aria-labelledby="edit-marker-modal"
                open={this.props.open}
                onClose={this.props.onClose}
                className={'modal-overall'}
            >
                <Paper style={styless.root} className="modal-marker-container">
                    <Typography variant="h5" component="h3" id="marker-title">
                        {this.props.marker.id === 0 ? "Add new place" : this.props.suggestion ? 'Make a suggestion' : 'Edit marker'}
                    </Typography>
                    <div id="position-marker">
                        {
                            Array.isArray(pos) ? pos.map(lt => Number(lt).toFixed(0)).join(", ") : pos
                        }
                    </div>
                    {this.state.done && this.props.suggestion ? <Typography variant='caption' component='p'>Thanks for making a suggestion!</Typography> : ''}
                    <div className="form-container">
                        <TextField
                            label="Name"
                            name='name'
                            value={this.state.form.name}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    notchedOutline: `input-fieldset ${this.state.form.name ? '' : 'empty'}`,
                                    input: 'input-marker'
                                }
                            }}
                        />
                        <TextField
                            label="Description"
                            name='description'
                            value={this.state.form.description}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    notchedOutline: `input-fieldset`,
                                    input: 'input-marker'
                                }
                            }}
                        />
                        <TextField
                            select
                            label="Category"
                            value={this.state.form.category}
                            onChange={this.handleChange}
                            name='category'
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    notchedOutline: `input-fieldset ${this.state.form.category ? '' : 'empty'}`,
                                    input: 'input-marker'
                                }
                            }}
                        >
                            {this.props.categories.filter(category => !!category.parent).map(category => <MenuItem value={category.id} key={category.id} >{category.name}</MenuItem>)}
                        </TextField>

                        <FormControlLabel
                            control={<Checkbox
                                checked={this.state.form.isDLC}
                                onChange={this.handleDLC}
                                value="isDLC"
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                            />}
                            label="DLC"
                        />
                        {!this.props.suggestion && !this.props.marker.private ? <FormControlLabel
                            control={<Switch
                                checked={this.state.form.private}
                                onChange={this.handlePrivate}
                                value="private"
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                classes={{
                                    checked: 'switch-checked-dot'
                                }}
                            />}
                            label="Keep this marker secret"
                        /> : null }
                        <div className={"button-container"}>
                            <Button variant="outlined" onClick={this.submit} style={this.isValid() ? styless.button : styless.buttonDisabled} disabled={!this.isValid()} >
                                Submit
                        </Button>
                        </div>
                    </div>
                </Paper>
            </Modal>
        </>;
    }
}
