import { Button } from "@material-ui/core";
import config from "./../../api/config";
import React from "react";


type OAuthType = 'facebook' | 'battlenet' | 'google' | 'steam'

interface IProps {
    type: OAuthType,
    register?: boolean,
}

export default class LoginButton extends React.Component<IProps> {
    renderButton = (type: OAuthType) => {
        return <Button className={`login-btn ${type}-btn`} variant="contained" component={props => <a {...props} href={`${config.apiAddress}auth/${type}`}/>}>{ this.props.register ? 'Register' :'Login'} with {type}</Button>
    }

    render() {
        return this.renderButton(this.props.type);
    }
}