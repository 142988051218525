import { Modal, Paper, TextField, Button, Snackbar } from "@material-ui/core";
import api from "../../../api/api";
import React from "react";
import { createStyles as makeStyles } from '@material-ui/core/styles';
import Alert from "../../elements/Alert";
import validateEmail from './../Users/emailValidator';

const styles = makeStyles({
    root: {
        width: '90%',
        maxWidth: '451px',
        alignSelf: 'center',
        padding: '15pt',
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '137px',
        paddingLeft: '137px',
        borderRadius: '30px',
        outline: 'none'
    },
    button: {
        border: 'none',
        background: '#54f2f2',
        height: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        color: 'white',
        width: '100%',
        marginTop: '20px',
        marginBottom: '70px'
    },
    contactTitle: {
        fontSize: '40px',
        fontWeight: 'bold',
        textAlign: 'center',
        fontFamily: 'Lato',
        marginTop: '70px',
        marginBottom: '30px'
    }
})
interface IProps {
    open: boolean,
    close: () => void
}
interface IForm {
    topic: string,
    message: string,
    email: string
}
interface IState {
    form: IForm,
    loading: boolean,
    contactSnackbar: boolean
}
export default class ContactModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            form: {
                topic: '',
                message: '',
                email: ''
            },
            loading: false,
            contactSnackbar: false
        }
    }

    submit = () => {
        this.setState({ loading: true }, () => {
            api.message.add(this.state.form, () => {
                this.setState({ loading: false, contactSnackbar: true }, this.props.close);
            });
        });
    }

    closeContactSnackbar = () => {
        this.setState({contactSnackbar: false})
    }

    handleChange = (type: keyof IForm) => (e: any) => {
        const val = e.target.value;
        this.setState(state => {
            state.form[type] = val;
            return state;
        });
    }

    isValid = () => {
        const { form } = this.state;
        return Boolean(form.message && form.email && validateEmail(form.email) && form.topic);
    }

    render() {
        return <>
            <Modal
                aria-labelledby="contact-modal"
                open={this.props.open}
                onClose={this.props.close}
                className="uhu"
            >
                <Paper style={styles.root}>
                    <div style={styles.contactTitle}>Contact with us</div>
                    <TextField
                        label="Email"
                        name='email'
                        value={this.state.form.email}
                        onChange={this.handleChange('email')}
                        margin="normal"
                        variant="outlined"
                        type="email"
                        inputProps={{
                            style: {
                                color: '#1b213780'
                            }
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: 'input-contact'
                            },
                            required: true
                        }}
                        InputLabelProps={{
                            classes: {
                                root: 'input-contact-label'
                            }
                        }}
                    />
                    <TextField
                        label="Topic"
                        name='topic'
                        value={this.state.form.topic}
                        onChange={this.handleChange('topic')}
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                            style: {
                                color: '#1b213780'
                            }
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: 'input-contact'
                            },
                            required: true
                        }}
                        InputLabelProps={{
                            classes: {
                                root: 'input-contact-label'
                            }
                        }}
                    />
                    <TextField
                        label="Message"
                        name='message'
                        multiline
                        value={this.state.form.message}
                        onChange={this.handleChange('message')}
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                            style: {
                                color: '#1b213780',
                                height: '184px'
                            },
                            required: true
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: 'input-contact'
                            }
                        }}
                        InputLabelProps={{
                            classes: {
                                root: 'input-contact-label'
                            }
                        }}
                    />
                    <Button variant="outlined" disabled={this.state.loading || !this.isValid()} style={styles.button} className="btn-send" onClick={this.submit} >
                        Send
                </Button>
                </Paper>
            </Modal>
            <Snackbar
                autoHideDuration={4000}
                open={this.state.contactSnackbar}
                onClose={this.closeContactSnackbar}
            >
                <Alert type="success">
                    Thank you. You will be messaged back through email.
                </Alert>
            </Snackbar>
        </>
    }
}