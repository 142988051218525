import React from 'react';
import { Create, Edit, SimpleForm, BooleanInput, TextInput, ReferenceField, TextField } from 'react-admin';

export const MessageEdit = (props: any) => (
	<Edit {...props}>
		<SimpleForm>
			<TextField label='ID' source='id' />
			<TextField source='email' required/>
			<TextField source='topic' required/>
			<TextField source='message' />
			<ReferenceField label="Author" source="user" reference="users" allowEmpty={true}>
				<TextField source="username" />
			</ReferenceField>
		</SimpleForm>
	</Edit>
)
