import React from 'react';
import { Create, Edit, SimpleForm, BooleanInput, TextInput, ReferenceField, TextField } from 'react-admin';

export const MarkerCreate = (props: any) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source='name'/>
			<TextInput source='description'/>
			<BooleanInput source='accepted' />
		</SimpleForm>
	</Create>
)

export const MarkerEdit = (props: any) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput label='ID' source='id' />
			<TextInput source='name' required/>
			<TextInput source='description' required/>
			<BooleanInput source='accepted' />
			<BooleanInput source='isDLC' label="DLC"/>
			<TextInput label='Position' source='position' />
			<ReferenceField label="Author" source="owner" reference="users" allowEmpty={true}>
				<TextField source="username" />
			</ReferenceField>
		</SimpleForm>
	</Edit>
)
