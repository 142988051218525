import { IconButton, Modal, Typography, Paper, TextField, Button, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core";
import api from "../../../api/api";
import React from "react";
import { Edit } from "@material-ui/icons";
import { createStyles as makeStyles } from '@material-ui/core/styles';
import ContactModal from './Contact';

const styles = makeStyles({
    button: {
        border: 'none',
        background: '#54f2f2',
        height: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        color: 'white',
        width: '70%'
    },
})
interface IState {
    open: boolean
}

export default class Contact extends React.Component<any, IState> {
    constructor(props: any){
        super(props);
        this.state = {
            open: false
        }
    }

    close = () => this.setState({open: false});

    open = () => this.setState({open: true});

    submit = () => {

    }

    render() {
        return <>
            <ContactModal
                open={this.state.open}
                close={this.close}
            />
            <Button variant="outlined"  className="btn" onClick={this.open} component="a" >
                Contact Us
            </Button>
        </>
    }
}