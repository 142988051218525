import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';

/*export const ReportCreate = (props: any) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source='name'/>
			<TextInput source='description'/>
		</SimpleForm>
	</Create>
)*/

export const ReportEdit = (props: any) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput disabled label='ID' source='id' />
			<TextInput disabled source='description'/>
			<TextInput disabled source='User.username' label='Username'/>
			<TextInput disabled source='Marker.name' label='Marker Name'/>
			<TextInput disabled source='Marker.id' label='Marker ID'/>
			<BooleanInput source='open' />
		</SimpleForm>
	</Edit>
)
