import React, { Component } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { Close } from "@material-ui/icons";

export const wait = async (time: number) => {
    return new Promise((res, rej) => {
        setTimeout(()=>{res()},time);
    });
}
export default class Toast extends Component<{ show: boolean, setToast: Function, text:string }> {
    handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.setToast(false);
    }

    render() {
        return (

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={this.props.show}
                autoHideDuration={4000}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.props.text}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        //className={this.classes.close}
                        onClick={this.handleClose}
                    >
                        <Close />
                    </IconButton>,
                ]}
            />

        )
    }
}