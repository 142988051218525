import React from 'react';
import { Create, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, FileInput, ImageField } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

export const CategoryCreate = (props: any) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source='name'/>
			<TextInput source='description'/>
			
			<ReferenceInput label="Parent Category" source="parent" reference="categories" filter={{ parent: null }}>
				<SelectInput optionText="name" resettable/>
			</ReferenceInput>
			<ColorInput label="Color"  source="color" />
			<FileInput source="icon" label="Icon" placeholder={<p>Drop your file here</p>}>
				<ImageField source="src" title="title" />
			</FileInput>
		</SimpleForm>
	</Create>
)

export const CategoryEdit = (props: any) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput disabled label='ID' source='id' />
			<TextInput source='name'/>
			<TextInput source='description'/>
			<FileInput source="icon" label="Icon" placeholder={<p>Drop your file here</p>}>
				<ImageField source="src" title="title" />
			</FileInput>
			<ColorInput label="Color" source="color" />
			<ReferenceInput label="Parent Category" source="parent" reference="categories" filter={{ parent: true }}>
				<SelectInput optionText="name" resettable/>
			</ReferenceInput>

		</SimpleForm>
	</Edit>
)
