import React from 'react';
import ApproveButton from './ApproveButton';
import { Create, Edit, SimpleForm, BooleanInput, TextField, BooleanField,Toolbar,DeleteButton } from 'react-admin';
const PostCreateToolbar = (props: any) => (
    <Toolbar {...props} >
		<ApproveButton />
		<DeleteButton/>
    </Toolbar>
);

export const SuggestionEdit = (props: any) => (
	<Edit {...props}>
		<SimpleForm toolbar={<PostCreateToolbar/>}>
			<TextField disabled label='ID' source='id' />
			<TextField source='name' required label='Suggested name'/>
			<TextField source='Marker.name' required label='Original name'/>
			<TextField source='description' required label='Suggested description'/>
			<TextField source='Marker.description' required label='Suggested description'/>
			<TextField source='Category.name' required label='Suggested category'/>
			<TextField source='Marker.Category.name' required label='Original category'/>
			<BooleanField source='isDLC' label="DLC" />
			<BooleanField source='private' label="Is private?" />
		</SimpleForm>
	</Edit>
)
