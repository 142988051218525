import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Button} from '@material-ui/core';
import {Check} from '@material-ui/icons'
import config from '../../../../api/config';
import { showNotification as showNotificationAction } from 'react-admin';
import { push as pushAction } from 'react-router-redux';

class ApproveButton extends Component<any,any> {
    handleClick = () => {
        const { push, record, showNotification } = this.props;
        const updatedRecord = { ...record, accept: true };

        fetch(`${config.apiAddress}suggestions/${record.id}`, {
            method: 'PATCH',
            body: JSON.stringify(updatedRecord),
            credentials: 'include',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(() => {
                showNotification('Suggestion approved');
                push('/suggestions');
            })
            .catch((e) => {
                console.error(e);
                showNotification('Error: suggestion not approved', 'warning')
            });
    }

    render() {
        return <Button variant="contained" onClick={this.handleClick} style={{marginRight:'8px', backgroundColor:'#88f34d'}}  ><Check style={{marginRight:'8px'}}/> Approve</Button>;
    }
}

export default connect(null, {
    showNotification: showNotificationAction,
    push: pushAction
})(ApproveButton);