import React from 'react';
import { Create, Edit, SimpleForm, BooleanInput, TextInput } from 'react-admin';

export const UserCreate = (props: any) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source='username'/>
			<TextInput source='password'/>
			<TextInput source='steamid'/>
			<TextInput source='facebookid'/>
			<BooleanInput source='admin' />
		</SimpleForm>
	</Create>
)

export const UserEdit = (props: any) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput disabled label='ID' source='id' />
			<TextInput source='username'/>
			<TextInput source='steamid'/>
			<TextInput source='facebookid'/>
			<BooleanInput source='admin' />
			<BooleanInput source="banned" />
		</SimpleForm>
	</Edit>
)
