import React, { Fragment } from 'react';
import { BulkDeleteButton, List, Datagrid, TextField, Pagination, ReferenceField } from 'react-admin';
import Alert from './../../../elements/Alert';
import { ColorField } from 'react-admin-color-input';

const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50]} {...props}/>

const BulkActionButtons = (props : any) => (<Fragment>
	<BulkDeleteButton {...props}/>
</Fragment>);

const CategoryList = (props: any) => (
	<>
		<Alert style={{marginBottom:'20px'}}>
				Warning: Removing a category will result in removing the markers as well!

		</Alert>
		<List {...props}
			bulkActionButtons={<BulkActionButtons/>}
			pagination={<PostPagination/>}
			perPage={50}
		>
			<Datagrid rowClick='edit'>
				<TextField source="id" sortable={true}/>
				<TextField source="name" sortable={true}/>
				<TextField source="description" sortable={false}/>
				<ColorField source="color" />
				<ReferenceField label="Parent category" source="parent" reference="categories" allowEmpty={true}>
					<TextField source="name" />
				</ReferenceField>
			</Datagrid>
		</List>
	</>
);

export default CategoryList;
